/* eslint-disable */
const { REACT_APP_MS_ENV } = process.env;
const imageCloudNameLive = 'dgcojyezg';
const imageCloudNameBeta = 'dglcrirjs';
const imageCloudNameQa = 'dfpffsf2y';
const imageCloudNameDev = 'dcevbvmdu';

const imageCloudPresetQa = 'n4lcu2tr';
const imageCloudPresetDev = 'dycthn0s';
const imageCloudPresetLive = 'hglnuurt';
const imageCloudPresetBeta = 'cw1lllrv';
const pdfTransactionsCloudNameLive = 'dgcojyezg';
const pdfTransactionsCloudNameBeta = 'dycthn0s';
const pdfTransactionsCloudNameQa = 'n4lcu2tr';
const pdfTransactionsCloudNameDev = 'dz4u73trs';
const PDF__TRANSACTIONS_PRESET = 'pdf_transaction_preset';

const constructApi = (env) => `https://${env}api.millionspaces.com/api/`;
const stripePublicKeyTest = 'pk_test_K1DWKnWgcWOpmAF0E5bqXvxH00mLVdtjKZ';
const stripePublicKeyLive = 'pk_live_51GM9hoDjxp508Iih83nZ4IvQW7ahzUJNcvamW3BCJ64ZtoKkpoc1xEvhqJt9i1ikzt6Udag3mrxSCQenieNw2zI200JN9dM5aX';

const cloudFolderLive = 'ms-singapore';
const cloudFolderBeta = 'ms-singapore';
const cloudFolderQae = 'ms-singapore';
const cloudFolderDev = 'ms-singapore';

/* google map api keys */
// const googleApiKeyQA = 'AIzaSyCKPdQUMcjoKxTbSeZECtK13E3RhYvh1cU';
// const googleApiKeyDev = 'AIzaSyCKPdQUMcjoKxTbSeZECtK13E3RhYvh1cU';
// const googleApiKeyBeta = 'AIzaSyCKPdQUMcjoKxTbSeZECtK13E3RhYvh1cU';
// const googleApiKeyLive = 'AIzaSyBUNB7r5HwOPjhE9nFjUlSjUhd3_NaM4lk';
const googleApiKeyQA = 'AIzaSyBUNB7r5HwOPjhE9nFjUlSjUhd3_NaM4lk';
const googleApiKeyDev = 'AIzaSyBUNB7r5HwOPjhE9nFjUlSjUhd3_NaM4lk';
const googleApiKeyBeta = 'AIzaSyBUNB7r5HwOPjhE9nFjUlSjUhd3_NaM4lk';
const googleApiKeyLive = 'AIzaSyBUNB7r5HwOPjhE9nFjUlSjUhd3_NaM4lk';



//-----------------QAE env variables---------------------------------
const servicesQAE = 'https://qa-api.millionspaces.com/api/';
const faceLoginBookUrlQa = 'https://qa-api.millionspaces.com/oauth2/authorize/facebook?redirect_uri=';
const googleLoginLoginBookUrlQa = 'https://qa-api.millionspaces.com/oauth2/authorize/google?redirect_uri=';
const AUTH_URLQAE = 'https://qa-api.millionspaces.com/auth';
const msUrlQa = 'http://new-qae.millionspaces.com.s3-website.ap-south-1.amazonaws.com';
const merchantIdQa = '1216308';
const redirectUrlQa = "https://d27q69pstjm6ae.cloudfront.net";

//-----------------Dev env variables---------------------------------
const servicesDev = 'http://18.188.176.75/api/';
const faceLoginBookUrlDev = 'http://18.188.176.75/oauth2/authorize/facebook?redirect_uri=';
const googleLoginLoginBookUrlDev = 'http://18.188.176.75/oauth2/authorize/google?redirect_uri=';
export const AUTH_URLDev = 'http://18.188.176.75/auth';
const msUrlDev = 'http://dev.millionspaces.com.s3-website-ap-southeast-1.amazonaws.com';
const merchantIdDev = '1216308';
const redirectUrlDev = "https://d27q69pstjm6ae.cloudfront.net";

//-----------------Beta env variables---------------------------------
const servicesBeta = 'https://beta-api.millionspaces.com/api/';
const faceLoginBookUrlBeta = 'https://beta-api.millionspaces.com/oauth2/authorize/facebook?redirect_uri=';
const googleLoginLoginBookUrlBeta = 'https://beta-api.millionspaces.com/oauth2/authorize/google?redirect_uri=';
export const AUTH_URLBeta = 'https://beta-api.millionspaces.com/auth';
const msUrlBeta = 'https://beta.millionspaces.com';
const merchantIdBeta = '216202';
const redirectUrlBeta = 'https://beta.millionspaces.com';

//-----------------production env variables---------------------------------
const servicesLive = 'https://prod.millionspaces.com/api/';
const faceLoginBookUrlLive = 'https://prod.millionspaces.com/oauth2/authorize/facebook?redirect_uri=';
const googleLoginLoginBookUrlLive = 'https://prod.millionspaces.com/oauth2/authorize/google?redirect_uri=';
export const AUTH_URLLive = 'https://prod.millionspaces.com/auth';
const msUrlLive = 'https://millionspaces.com';
const merchantIdLive = '216202';
const redirectUrlLive = 'https://millionspaces.com';

let cloudinaryAccount = imageCloudNameQa;
const cloudFront = 'https://d24f4cafkqnfue.cloudfront.net/';
const cloudinaryQa = `https://res.cloudinary.com/${imageCloudNameQa}/`;
const cloudinaryDev = `https://res.cloudinary.com/${imageCloudNameDev}/`;
const cloudinaryLive = `https://res.cloudinary.com/${imageCloudNameLive}/`;
const cloudinaryBeta = `https://res.cloudinary.com/${imageCloudNameBeta}/`;
const mediaURL = 'https://media.millionspaces.com/';

const facebookTrackingCodeQa = '1342333682800326';
const facebookTrackingCodeDev = '218398943083397';
const facebookTrackingCodeBeta = '';
const facebookTrackingCodeLive = '725580381657513';

const facebookMetaTag = 'NO_META_FOUND';
const facebookMetaTagLive = '1pktvbyvderccu425tbrzaqwvnqt1p';

const facebookMessengerAppId = '1050874385276824';
const facebookMessengerPageId = '102728468702278';

const facebookMessengerAppIdProd = '1050874385276824';
const facebookMessengerPageIdProd = '504081616624543';

// export const BASE_URL = mockApi;
let BASE_URL = servicesDev;
let IMAGES_BASE_URL = cloudinaryDev;
let AUTH_URL = AUTH_URLDev;
let IMAGE_CLOUD_FOLDER = cloudFolderDev;
let STRIPE_PUBLIC_KEY = stripePublicKeyTest;
let IMAGE_CLOUD_NAME = imageCloudNameDev;
let IMAGE_CLOUD_PRESET = imageCloudPresetDev;
let PDF__TRANSACTIONS_CLOUDE_NAME = pdfTransactionsCloudNameDev;
let FACEBOOK_META_TAG = facebookMetaTag;


let api = constructApi(REACT_APP_MS_ENV === 'qae' ? 'q' : '');

let FACEBOOK_LOGIN_URL = faceLoginBookUrlDev;
let GOOGLE_LOGIN_URL = googleLoginLoginBookUrlDev;
let MS_URL = msUrlDev;
let GOOGLE_ANALYTICS_TRACKING_CODE = 'UA-170606572-1';
let googleMapAPIKey = googleApiKeyDev;
let MERCHANT_ID = merchantIdDev;
let SANDBOX = true;

let FACEBOOK_APP_ID = facebookMessengerAppId;
let FACEBOOK_PAGE_ID = facebookMessengerPageId;

let FACEBOOK_PIXEL_TRACKING_CODE = facebookTrackingCodeDev;

let REDIRECT_URL = redirectUrlDev;

if (REACT_APP_MS_ENV === 'qae') {
  console.log(constructApi(REACT_APP_MS_ENV === 'qae' ? 'q' : ''));
  cloudinaryAccount = imageCloudNameQa;
  MS_URL = msUrlQa;
  IMAGE_CLOUD_PRESET = imageCloudPresetQa;
  PDF__TRANSACTIONS_CLOUDE_NAME = pdfTransactionsCloudNameQa;
  BASE_URL = servicesQAE;
  FACEBOOK_LOGIN_URL = faceLoginBookUrlQa;
  GOOGLE_LOGIN_URL = googleLoginLoginBookUrlQa;
  STRIPE_PUBLIC_KEY = stripePublicKeyTest;
  googleMapAPIKey = googleApiKeyQA;
  IMAGE_CLOUD_FOLDER = cloudFolderQae;
  IMAGES_BASE_URL = cloudinaryQa;
  IMAGE_CLOUD_NAME = imageCloudNameQa;
  AUTH_URL = AUTH_URLQAE;
  FACEBOOK_PIXEL_TRACKING_CODE = facebookTrackingCodeQa;
  MERCHANT_ID = merchantIdQa;
  FACEBOOK_APP_ID = facebookMessengerAppId;
  FACEBOOK_PAGE_ID = facebookMessengerPageId;
  REDIRECT_URL = redirectUrlQa;
} else if (REACT_APP_MS_ENV === 'dev') {
  cloudinaryAccount = imageCloudNameDev;
  IMAGE_CLOUD_PRESET = imageCloudPresetDev;
  PDF__TRANSACTIONS_CLOUDE_NAME = pdfTransactionsCloudNameDev;
  BASE_URL = servicesDev;
  FACEBOOK_LOGIN_URL = faceLoginBookUrlDev;
  GOOGLE_LOGIN_URL = googleLoginLoginBookUrlDev;
  MS_URL = msUrlDev;
  IMAGES_BASE_URL = cloudinaryDev;
  AUTH_URL = AUTH_URLDev;
  STRIPE_PUBLIC_KEY = stripePublicKeyTest;
  googleMapAPIKey = googleApiKeyDev;
  IMAGE_CLOUD_FOLDER = cloudFolderDev;
  IMAGE_CLOUD_NAME = imageCloudNameDev;
  FACEBOOK_PIXEL_TRACKING_CODE = facebookTrackingCodeDev;
  MERCHANT_ID = merchantIdDev;
  FACEBOOK_APP_ID = facebookMessengerAppId;
  FACEBOOK_PAGE_ID = facebookMessengerPageId;
  REDIRECT_URL = redirectUrlDev;
} else if (REACT_APP_MS_ENV === 'beta') {
  cloudinaryAccount = imageCloudNameBeta;
  IMAGE_CLOUD_PRESET = imageCloudPresetBeta;
  PDF__TRANSACTIONS_CLOUDE_NAME = pdfTransactionsCloudNameBeta;
  BASE_URL = servicesBeta;
  FACEBOOK_LOGIN_URL = faceLoginBookUrlBeta;
  GOOGLE_LOGIN_URL = googleLoginLoginBookUrlBeta;
  MS_URL = msUrlBeta;
  IMAGES_BASE_URL = cloudinaryBeta;
  AUTH_URL = AUTH_URLBeta;
  STRIPE_PUBLIC_KEY = stripePublicKeyTest;
  googleMapAPIKey = googleApiKeyBeta;
  IMAGE_CLOUD_FOLDER = cloudFolderBeta;
  IMAGE_CLOUD_NAME = imageCloudNameBeta;
  FACEBOOK_PIXEL_TRACKING_CODE = facebookTrackingCodeBeta;
  MERCHANT_ID = merchantIdDev;
  SANDBOX = true;
  FACEBOOK_APP_ID = facebookMessengerAppId;
  FACEBOOK_PAGE_ID = facebookMessengerPageId;
  REDIRECT_URL = redirectUrlBeta;
} else if (REACT_APP_MS_ENV === 'live') {
  IMAGE_CLOUD_NAME = imageCloudNameLive;
  IMAGE_CLOUD_PRESET = imageCloudPresetLive;
  PDF__TRANSACTIONS_CLOUDE_NAME = pdfTransactionsCloudNameLive;
  cloudinaryAccount = imageCloudNameLive;
  BASE_URL = servicesLive;
  FACEBOOK_LOGIN_URL = faceLoginBookUrlLive;
  GOOGLE_LOGIN_URL = googleLoginLoginBookUrlLive;
  MS_URL = msUrlLive;
  IMAGES_BASE_URL = cloudinaryLive;
  AUTH_URL = AUTH_URLLive;
  STRIPE_PUBLIC_KEY = stripePublicKeyLive;
  googleMapAPIKey = googleApiKeyLive;
  IMAGE_CLOUD_FOLDER = cloudFolderLive;
  IMAGE_CLOUD_NAME = imageCloudNameLive;
  FACEBOOK_PIXEL_TRACKING_CODE = facebookTrackingCodeLive;
  GOOGLE_ANALYTICS_TRACKING_CODE = 'UA-173993983-1';
  MERCHANT_ID = merchantIdLive;
  SANDBOX = false;
  FACEBOOK_META_TAG = facebookMetaTagLive;
  FACEBOOK_APP_ID = facebookMessengerAppIdProd;
  FACEBOOK_PAGE_ID = facebookMessengerPageIdProd;
  REDIRECT_URL = redirectUrlLive;
}

export {
  api,
  IMAGE_CLOUD_NAME,
  IMAGE_CLOUD_PRESET,
  PDF__TRANSACTIONS_PRESET,
  PDF__TRANSACTIONS_CLOUDE_NAME,
  FACEBOOK_LOGIN_URL,
  GOOGLE_LOGIN_URL,
  REACT_APP_MS_ENV,
  MS_URL,
  BASE_URL,
  IMAGES_BASE_URL,
  AUTH_URL,
  STRIPE_PUBLIC_KEY,
  GOOGLE_ANALYTICS_TRACKING_CODE,
  FACEBOOK_PIXEL_TRACKING_CODE,
  googleMapAPIKey,
  IMAGE_CLOUD_FOLDER,
  MERCHANT_ID,
  SANDBOX,
  FACEBOOK_META_TAG,
  FACEBOOK_APP_ID,
  FACEBOOK_PAGE_ID,
  REDIRECT_URL
};

// Dates
export const TIME_STRING_FORMAT = 'hh:mm A';
export const DATE_FORMAT = 'DD MMM YY';
export const DATE_FORMAT_FILTER = 'DD MMM YYYY';

export const CALENDER_DATE_FORMAT = 'DD-MM-YYYY';
export const CALENDER_DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_PICKER_DATE_FORMAT = 'MM/DD/YYYY';
export const NEW_DATE_PICKER_DATE_FORMAT = 'DD MMM YYYY';

// Messages

export const GOOGEL_PLACE_ID_API = `https://maps.googleapis.com/maps/api/geocode/json`;
export const googleApi = `https://maps.googleapis.com/maps/api/place/autocomplete/json`;
export const iconApi = `${IMAGES_BASE_URL}assets/Icons/Icons/`;

//App ids

export const FacebookAppId = '1050874385276824';
export const GoogleAppId = '47572526679-uepegutd1ks8netbq3tjjebtcg6mrso2.apps.googleusercontent.com';

export const countryRestrictions = 'country:sg|country:lk';

// Payhere details
export const notify_url = `${BASE_URL}pay/sri-lanka/notify`;
export const merchant_id = MERCHANT_ID;
export const payhere_preApprove = 'https://sandbox.payhere.lk/pay/preapprove';
export const sandbox = SANDBOX;

// Generate URL parameters for image scaling.
export const setImageQuality = () => {
  return 'q_50/';
};

//Google calendar events
export const client_id='111538526116-hv5iqdggd8lsn03oa481uech86a029av.apps.googleusercontent.com';
export const client_secret='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';
export const scopes = 'https://www.googleapis.com/auth/calendar.readonly';


export const updated_client_id='202220924937-2ojcmrn76h6uo9hlhgsn8nbjrv76fmod.apps.googleusercontent.com';
export const updated_client_secret='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';
export const updated_scopes = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar.readonly';